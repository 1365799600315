<template>
<Modal v-model="showModal" :mask-closable="false" draggable width="500px">
  <div slot="header" class="monitor-header">
    通信监听
    <Input v-model="devcode" clearable :maxlength="20" placeholder="请输入设备ID" style="width: 200px;margin: 0 10px"></Input>
    <Button :type="monitorDeviceId == 0 ? 'primary' : 'success'" style="margin-right: 8px" @click="switchMonitor">{{monitorDeviceId == 0 ? '开始监听' : '停止监听'}}</Button>
  </div>
  <div class="cust-list">
    <template v-for="(item, idx) in trans">
      <div class="cust-item" :key="idx">
        <div class="item-header">
          <div class="item-name" :style="{color: item.send ? 'blue' : 'green'}">{{topCode}}</div>
          <div class="item-time" :style="{color: item.send ? 'blue' : 'green'}">{{new Date(item.ts).format('yyyy-MM-dd HH:mm:ss.fff')}}</div>
          <div class="item-type" :style="{color: item.send ? 'blue' : 'green'}" @click="showPrococol(item)">{{item.send ? '发送' : '接收'}}</div>
          <div class="item-diff tr" :style="{color: item.send ? 'blue' : 'green'}">{{getTimeDiff(item, idx)}}</div>
        </div>
        <div class="item-content" :style="{color: item.send ? 'blue' : 'green'}">{{item.bytes}}</div>
      </div>
    </template>
  </div>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    <Button style="margin-right: 8px" @click="clear">清除记录</Button>
    <Button type="success" @click="exportData">导出</Button>
    <ModalProtocolResult v-model="showEditModal" :item="editItem" />
  </div>
</Modal>
</template>
<script>
import {mapState} from 'vuex'
import ModalProtocolResult from './ModalProtocolResult'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'ModalDataMonitor',
  components:{
    ModalProtocolResult
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showModal: this.value,
      devcode: 'all',
      tabHead: {
        name: '名称',
        ts: '时间',
        send: '方向',
        content: '内容'
      },
      topId: 0,
      topCode: '',
      showEditModal: false,
      editItem: {},
    }
  },
  computed: {
    ...mapState('cmd', ['trans', 'monitorDeviceId']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.devcode = '';
      }else{
        this.$store.commit('cmd/disableMonitorDevices');
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
  },
  destroyed: function(){
    this.$store.commit('cmd/disableMonitorDevices');
  },
  methods: {
    showPrococol: function(params){
      if(params.send)return;
      // console.log('show protocol', params);
      this.editItem = {id: this.topCode, content: params.bytes };
      this.showEditModal = true;
    },
    getTimeDiff: function(item, idx){
      if(idx == 0)return 0;
      let last = this.trans[idx - 1];
      return (item.ts - last.ts);
    },
    switchMonitor: function(){
      if(this.monitorDeviceId == 0){
        let devid = this.devcode.trim();
        if(devid.length == 0){
          this.$Message.warning('请输入设备ID');
          return;
        }
        this.$axios.get(`//${this.domains.trans}/station/topdevice/${devid}`).then(res => {
          if(res.code != 0){
            // this.$Message.error(res.data);
            return;
          }
          this.topId = res.data.id;
          this.topCode = res.data.code;
          this.$store.commit('cmd/enableMonitorDevices', [this.topId]);
        })
      }else{
        this.$store.commit('cmd/disableMonitorDevices');
      }
    },
    exportData(){
      let trans = this.trans.map(p => {return {name: this.topCode, ts: new Date(p.ts).format('yyyy-MM-dd HH:mm:ss.fff'), send: p.send ? '发送' : '接收', content: p.bytes};});
      exportXlsx(trans, this.tabHead, '通信记录导出');
    },
    clear: function(){
      this.$store.commit('cmd/clearTransData');
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.monitor-header{
  display: flex;
  align-items: center;
}
.cust-list{
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: auto;
}
.cust-item{
  border: solid 1px rgba(128, 128, 128, 0.274);
  margin: 5px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.item-header{
  height: 20px;
  width: 100%;
  display: flex;
}
.item-name{
  width: 100px;
  flex: auto;
  margin-right: 10px;
  /* border-right: solid 1px gray; */
  white-space: nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.item-time{
  width: 170px;
  flex: none;
  /* border-right: solid 1px gray; */
}
.item-type{
  width: 30px;
  flex: none;
  
  /* border: solid 1px gray; */
}
.item-diff{
  width: 50px;
  flex: none;
  /* border: solid 1px gray; */
}
.item-content{
  width: 100%;
  border-top: solid 1px rgba(128, 128, 128, 0.274);
  padding-top: 5px;
  /* white-space: nowrap; */
}
.cust-item:hover{
  border: solid 1px blue;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>