<template>
<Modal v-model="showModal" :mask-closable="false" draggable width="1200px">
  <div slot="header">在线连接查询 - {{tabData.length}}</div>
  <div slot="footer">
    <Input v-model="devcode" clearable :maxlength="20" placeholder="请输入设备ID" style="width: 200px;margin: 0 10px; float:left;"></Input>
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    <Button type="info" :loading="loading" @click="getList" style="margin-right: 10px;">查询</Button>
    <Button type="success" @click="exportData">导出</Button>
  </div>
  <u-table :data="filterData" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="500" stripe>
    <u-table-column type="index"></u-table-column>
    <u-table-column prop="conn" label="客户端" sortable fit width="230"></u-table-column>
    <u-table-column prop="code" label="注册包" sortable fit width="180"></u-table-column>
    <u-table-column prop="ctime" label="连接时间" sortable fit width="160"></u-table-column>
    <u-table-column prop="ttime" label="通信时间" sortable fit width="160"></u-table-column>
    <u-table-column prop="devid" label="关联设备" sortable width="110"></u-table-column>
    <u-table-column prop="name" label="设备名称" sortable fit width="250"></u-table-column>
    <u-table-column prop="type" label="类型名称" sortable fit width="220"></u-table-column>
    <u-table-column width="120" fixed="right">
      <template slot-scope="scope">
        <Button size="small" type="success" style="margin-right: 5px" @click="handleDebug(scope)">调试</Button>
        <Button size="small" type="error" @click="handleDelete(scope)">断开</Button>
      </template>
    </u-table-column>
  </u-table>
  <ModalDebug v-model="showDebugModal" :item="editItem" />
  <Spin size="large" fix v-if="loading"></Spin>
</Modal>
</template>
<script>
import { mapState } from 'vuex'
import ModalDebug from './ModalDebug'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'ModalTransOnlines',
  components:{
    ModalDebug
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showModal: this.value,
      showDebugModal: false,
      loading: false,
      curTab: 'group',
      tabHeight:1,
      tabData:[//列表数据
      ],
      tabHead:{ //头部标题
        conn: "客户端",
        code: '注册包',
        ctime: '连接时间',
        ttime: "通信时间",
        devid: '是否关联设备',
        name: '设备名称',
        type: '类型名称',
      },
      editItem: {},
      devcode: '',
      filterData: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.getList();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
    devcode(){
      this.handelFilterData();
    }
  },
  mounted: function(){
  },
  destroyed: function(){
  },
  methods: {
    handelFilterData(){
      // console.log('get trans list', this.tabData)
      if(this.devcode.length > 0){
        this.$set(this, 'filterData', this.tabData.filter(p => p.code && p.code.indexOf(this.devcode) != -1));
      }else{
        this.$set(this, 'filterData', this.tabData);
      }
    },
    handleDebug: function(params){
      this.editItem = {data: params.row.conn, code: params.row.code};
      this.showDebugModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要断开连接[${params.row.conn}]吗?`,
        onOk: async () => {
          this.$axios.delete(`//${this.domains.trans}/station/search/trans/${params.row.conn}`).then(res => {
            if(res.code == 0){
              this.$Message.info("已断开连接");
              this.getList();
            }else{
              // this.$Message.error(res.data);
            }
          });
        }
      });
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
    exportData(){
      if(this.tabData.length == 0){
        this.$Message.warning('当前无数据');
        return;
      }
      let name = '设备在线连接导出';
      exportXlsx(this.tabData, this.tabHead, name);
    },
    getList(){
      this.loading = true;
      this.$axios.get(`//${this.domains.trans}/station/search/trans`).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
          //  console.log(res.data)
        }else{
          res.data.map(item => {
            item.name = item.devid > 0 ? item.name : '';
            item.type = item.devid > 0 ? item.type : '';
            item.devid = item.devid > 0 ? '已关联' : '未关联';

          })
          this.$set(this, 'tabData', res.data);
          this.handelFilterData();
        }
      });
    },
    cancel: function(){
      this.showModal = false;
    }
  }
}
</script>
<style scoped>
.search-data-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header{
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content{
  height: 100px;
  flex: auto;
  /* border: solid 1px blue; */
}
.search-data-query{
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
  justify-content: flex-end;
}
.search-data-view{
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
</style>