<template>
  <div class="switch-organization" v-if="showModal" @click.stop="stopClick()">
    <div class="organization-box">
      <div class="organization-top">
        <img :src="img.home.switchOrganization.switchLogo" alt="" class="logo">
        <img :src="img.home.switchOrganization.cancel" alt="" class="cancel" @click="cancel()">
      </div>
      <div class="user-list">
        <div class="center-menu">
          <div class="menu-item" v-for="(item, idx) in appTypes" :style="{backgroundImage: `url(${item.code == cur?img.home.switchRoles.organizeActive:img.home.switchRoles.organize})`}" :key="idx" @click="switchMenu(item)">
            <img :src="showImg(item.name)" />
            <div class="menu-item-title">{{item.name}}</div>
          </div>
        </div>
        <div class="user-search">
          <Input v-model="searchVal" icon="ios-search-strong" class="user-search-input" :placeholder="showLang('save.keywords')"></Input>
        </div>
        <div class="cust-area">
          <div v-for="(item, idx) in searchData" :key="idx" @click.stop="selectCust(item)" :class="['cust-item', item.id == customerId && cur == appType ? 'cur-item':'']">
            <span :class="['circular', item.id == customerId && cur == appType ? 'circular-active':'']"><i class="spot-active" v-if="item.id == customerId && cur == appType"></i></span>
            {{item.title}} - {{item.name}}
            <img :src="img.home.switchOrganization.select" alt="" v-if="item.id == customerId && cur == appType" class="select-active">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'ModalSwitchCustomer',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      searchVal: "",
      searchData: [],
      showModal: this.value,
      cur: this.appType,
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('auth', ['token', 'user', 'customerId', 'appTypes', 'appType']),
    curCust: function () {
      return this.customers.filter(p => p.id == this.customerId)[0];
    },
    custs: function () {
      let apps = this.appTypes.filter(p => p.code == this.cur);
      if (apps.length == 0) return [];
      return apps[0].custs;
    },
  },
  watch: {
    custs() {
      this.$set(this, 'searchData', this.custs);
    },
    searchVal() {
      let arr = this.custs.filter(item => item.name.indexOf(this.searchVal) != -1 || item.title.indexOf(this.searchVal) != -1);
      this.$set(this, 'searchData', arr);
    },
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.cur = this.appType;
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  methods: {
    showImg(type) {
      let imgUrl = this.img.home.switchRoles.lamp;
      switch (type) {
        case '城市照明':
          imgUrl = this.img.home.switchRoles.lamp
          break;
        case '工厂照明':
          imgUrl = this.img.home.switchRoles.factory
          break;
        case '智慧灯杆':
          imgUrl = this.img.home.switchRoles.streetLamp
          break;
      }
      return imgUrl;
    },
    selectCust: function (item) {
      let apps = this.appTypes.filter(p => p.code == this.cur);
      if (apps.length == 0) return;
      let app = apps[0];
      if (this.customerId == item.id && this.appType == app.code) return;
      let appChange = true;// this.appType != app.code;
      this.$store.dispatch('auth/switchAppCust', { app: app.code, cust: item.id }).then(res => {
        if (res.code == 0) {
          this.$Message.info(this.showLang('system.switch.success'));
          this.showModal = false;
          if (appChange) {
            setTimeout(() => {
              // console.log('go router', app.url)
              this.searchVal = "";
              this.$router.replace(app.url);
              this.$store.commit('group/selectNodeById', {nodeId: 0, type: 'root'})
              setTimeout(() => {
                window.location.reload();
              }, 200);
            }, 200);
          }
        }
      });
    },
    stopClick() {
    },
    switchMenu(item) {
      this.cur = item.code
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.center-menu {
  /* border: solid 1px red; */
  flex: none;
  height: 200px;
  display: flex;
  justify-content: space-between;
}
.cust-area {
  height: 360px;
  overflow-y: auto;
}
.menu-item {
  cursor: pointer;
  width: 148px;
  flex: none;
  height: 205px;
  color: #ffffff;
  position: relative;
}
.menu-selected {
  border: solid 1px red;
}
.menu-item img {
  position: absolute;
  left: 50%;
  margin-left: -44px;
  top: 40px;
}
input::placeholder {
  color: #45689c;
}
.user-search >>> input::-webkit-input-placeholder {
  color: #45689c;
}
.menu-item-title {
  position: absolute;
  bottom: 13px;
  left: 10px;
  width: 130px;
  height: 35px;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #0591fa;
  background-image: -webkit-linear-gradient(
    bottom,
    #002e50,
    #51b4feff,
    #ffffff
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.switch-organization {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(1, 11, 22, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 501;
}
.organization-box {
  width: 982px;
  height: 819px;
  background: linear-gradient(90deg, #001b35, #001b35, #001b35);
  border-radius: 12px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -491px;
  margin-top: -391px;
  display: flex;
  flex-direction: column;
}
.cust-list {
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow: hidden;
}
.organization-top {
  height: 50px;
  flex: none;
}
.organization-top .logo {
  position: absolute;
  left: 50%;
  margin-left: -151px;
  top: 0;
}
.organization-top .cancel {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.user-search {
  margin: 40px auto 30px;
}
.user-search-input >>> .ivu-input {
  width: 382px;
  height: 44px;
  background: #15335f;
  border-radius: 8px;
  margin: 0 auto;
  text-align: center;
  border: none;
  color: #45689c;
  font-size: 16px;
}
.user-search-input >>> .ivu-input-icon {
  left: 340px;
  top: 5px;
  color: #fff;
}

.user-list {
  width: 652px;
  height: 500px;
  flex: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  /* padding-top: 20px; */
  margin-top: 40px;
}
.cust-item {
  width: 100%;
  height: 44px;
  background: #153b6d;
  border-radius: 8px;
  margin-bottom: 18px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 44px;
  cursor: pointer;
}
.cust-item.cur-item {
  box-sizing: border-box;
  background: linear-gradient(0deg, #153b6d, #20559a);
  border: 1px solid #01cfd0;
}
.circular {
  width: 14px;
  height: 14px;
  border: 1px solid #447bc8;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px 0 20px;
  line-height: 54px;
  vertical-align: middle;
}
.circular.circular-active {
  border: 1px solid #00f6f7;
}
.spot-active {
  width: 8px;
  height: 8px;
  background: #02eef2;
  border: 1px solid #00f6f7;
  border-radius: 50%;
  display: block;
  margin: 2px 0 0 2px;
}
.select-active {
  float: right;
  margin-right: 20px;
  margin-top: 10px;
}
</style>