<template>
  <Modal v-model="showModal" :mask-closable="false" draggable width="600px">
    <div slot="header">
      {{showLang('com.site.device.where')}}
    </div>
    <Form ref="form" :model="form" :label-width="70">
      <FormItem prop="code" :label="showLang('com.site.deviceID')">
        <div style="display: flex">
          <Input ref="code" type="text" maxlength="30" v-model="form.code" placeholder=""></Input>
          <Button style="margin-left: 8px" type="primary" @click="getDevicePos">{{showLang('com.op.query')}}</Button>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.export.cn.app')">
        <div class="cust-item">{{pos.appTypeName}}</div>
      </FormItem>
      <FormItem :label="showLang('com.export.cn.customer')">
        <div class="cust-item">{{pos.custName}}</div>
      </FormItem>
      <FormItem :label="showLang('com.export.cn.group.relate')">
        <div class="cust-item">{{pos.groupName}}</div>
      </FormItem>
      <FormItem :label="showLang('station.belong')">
        <div class="cust-item">{{pos.stationName}}</div>
      </FormItem>
      <FormItem :label="showLang('com.superior.dev')">
        <div class="cust-item">{{pos.topName}}</div>
      </FormItem>
      <FormItem :label="showLang('com.export.cn.devname')">
        <div class="cust-item">{{pos.deviceName}}</div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <Button v-if="canSwitch" type="primary" @click="switchCust">{{showLang('system.jump.custom.app')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalWhereDevice',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showModal: this.value,
      form: {
        code: '',
      },
      pos: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('auth', ['user', 'customerId', 'appType', 'appTypes', 'ops']),
    canSwitch: function () {
      if (!this.pos.appType || !this.pos.custId) return false;
      if (this.pos.appType == this.appType && this.pos.custId == this.customerId) return false;
      let ts = this.appTypes.filter(p => p.code == this.pos.appType);
      if (ts.length == 0) return false;
      let app = ts[0];
      let cs = app.custs.filter(p => p.id == this.pos.custId);
      return cs.length > 0;
    },
  },
  watch: {
    value(newVal) {
      // console.log('modal data send rec1')
      this.showModal = newVal;
      if (newVal) {
        this.code = '';
      }
    },
    showModal(val) {
      // console.log('modal data send rec2')
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    switchCust: function () {
      let ts = this.appTypes.filter(p => p.code == this.pos.appType);
      if (ts.length == 0) return false;
      let app = ts[0];
      // let cs = app.custs.filter(p => p.id == this.pos.custId);
      this.$store.dispatch('auth/switchAppCust', { app: this.pos.appType, cust: this.pos.custId }).then(res => {
        if (res.code == 0) {
          this.$Message.info(this.showLang('system.switch.success'));
          this.showModal = false;
          setTimeout(() => {
            // this.$router.go(0);
            // this.searchVal="";
            this.$router.replace(app.url);
          }, 200);
        }
      });
    },
    getDevicePos: function () {
      if (this.form.code == '' || this.form.code.length > 30) {
        this.$Message.warning(this.showLang('save.device.id.length' + 30));
        return;
      }
      this.$axios.get(`//${this.domains.trans}/station/where/${this.form.code}`).then(res => {
        if (res.code == 0) {
          this.$set(this, 'pos', res.data);
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.cust-item {
  border: solid 1px rgba(128, 128, 128, 0.274);
  /* margin: 5px; */
  padding: 2px 10px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: bolder;
  font-size: 20px;
  height: 38px;
}
.item-header {
  height: 20px;
  width: 100%;
  display: flex;
}
.item-name {
  width: 100px;
  flex: auto;
  margin-right: 10px;
  /* border-right: solid 1px gray; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-time {
  width: 170px;
  flex: none;
  /* border-right: solid 1px gray; */
}
.item-type {
  width: 30px;
  flex: none;
  /* border-right: solid 1px gray; */
}
.item-content {
  width: 100%;
  border-top: solid 1px rgba(128, 128, 128, 0.274);
  padding-top: 5px;
  /* white-space: nowrap; */
}
.cust-item:hover {
  border: solid 1px blue;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>